import api from './Api'

import { getTablePaginationParams, getResourceRequestQuery } from './helpers'
import { dateTimeRangeInUTC, getUserTimeZoneDate } from '@/utils/dateHelpers'

const ENDPOINTS = {
  calls: (id = '') => (id ? `/calls/${id}` : `/calls`),
  liveCalls: () => `/liveCalls`,
  lastTestCall: () => `/lastTestCall`,
  pauseRecording: (id) => `${ENDPOINTS.calls(id)}/actions/pauseRecording`,
  startRecording: (id) => `${ENDPOINTS.calls(id)}/actions/startRecording`,
  dropVoicemail: (id) => `${ENDPOINTS.calls(id)}/actions/dropVoicemail`,
  doubleDial: (id) => `${ENDPOINTS.calls(id)}/actions/doubleDial`,
  startTransfer: (id) => `${ENDPOINTS.calls(id)}/actions/startTransfer`,
  startAddCall: (id) => `${ENDPOINTS.calls(id)}/actions/startAddCall`,
  removeParticipant: (id) => `${ENDPOINTS.calls(id)}/actions/removeParticipant`,
  abortTransfer: (id) => `${ENDPOINTS.calls(id)}/actions/abortTransfer`,
  abortAddCall: (id) => `${ENDPOINTS.calls(id)}/actions/abortAddCall`,
  completeTransfer: (id) => `${ENDPOINTS.calls(id)}/actions/completeTransfer`,
  mergeAddCall: (id) => `${ENDPOINTS.calls(id)}/actions/mergeAddCall`,
  callFeedback: (id) => `${ENDPOINTS.calls(id)}/actions/feedback`,
  regenerateTranscription: (id) =>
    `${ENDPOINTS.calls(id)}/actions/regenerateTranscription`,
  findProspectMatches: (id) => `${ENDPOINTS.calls(id)}/findProspectMatches`,
  linkProspect: (id) => `${ENDPOINTS.calls(id)}/linkProspect`,
  syncCallToCrm: (id) => `${ENDPOINTS.calls(id)}/syncCrmLog`,
  bulkExportCalls: () => `/bulk/calls/actions/export`,
  opts: {
    externalId: (id) => `externalId=${id}`,
    dialedAt: (dateRange) => `dialedAt=${dateRange}`,
    userId: (id) => `userIds=${id}`,
    exclusions: (ids = []) => (ids.length ? `exclusions=${ids.join(',')}` : ``),
    orderBy: (val) => `orderBy=${val}`,
  },
}

export default {
  getCalls(options = {}) {
    let path = ENDPOINTS.calls()
    let pathParams = []

    const {
      dateFrom,
      dateTo,
      dateRange,
      filtersQuery,
      userId,
      search,
      resourceRequests,
    } = options

    if (userId) pathParams.push(ENDPOINTS.opts.userId(userId))

    if (filtersQuery) pathParams.push(filtersQuery)

    if (dateFrom || dateTo) {
      pathParams.push(
        ENDPOINTS.opts.dialedAt(
          dateTimeRangeInUTC(
            getUserTimeZoneDate(dateFrom),
            getUserTimeZoneDate(dateTo)
          )
        )
      )
    } else if (dateRange) {
      pathParams.push(ENDPOINTS.opts.dialedAt(dateRange))
    }

    if (search) pathParams.push(`search=${search}`)

    // Pagination
    const pagParams = getTablePaginationParams(options)
    if (pagParams) pathParams.push(pagParams)

    if (resourceRequests)
      pathParams.push(getResourceRequestQuery(resourceRequests))

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path, { abortKey: 'GET_CALLS' })
  },
  getCallById(id) {
    let path = ENDPOINTS.calls(id)
    return api.get(path)
  },
  getCallByExternalId(id) {
    let path = ENDPOINTS.calls() + '?' + ENDPOINTS.opts.externalId(id)
    return api.get(path)
  },
  getLastTestCall() {
    return api.get(ENDPOINTS.lastTestCall())
  },
  findProspectMatches(payload) {
    const { id, search } = payload
    let path = ENDPOINTS.findProspectMatches(id)
    if (search) path += `?search=${search}`

    return api.get(path)
  },
  linkProspectToCall(id, payload) {
    return api.post(ENDPOINTS.linkProspect(id), payload)
  },
  updateCall({ callId, call }) {
    return api.put(ENDPOINTS.calls(callId), call)
  },
  regenerateTranscription(callId) {
    return api.post(ENDPOINTS.regenerateTranscription(callId))
  },
  pauseRecording(id) {
    return api.post(ENDPOINTS.pauseRecording(id))
  },
  startRecording(id) {
    return api.post(ENDPOINTS.startRecording(id))
  },
  dropVoicemail(id) {
    return api.post(ENDPOINTS.dropVoicemail(id))
  },
  doubleDial(id) {
    return api.post(ENDPOINTS.doubleDial(id))
  },
  addToCall(payload) {
    return api.post(ENDPOINTS.startAddCall(payload.callId), {
      to: payload.to,
      auto_merge: payload.auto_merge,
    })
  },
  removeFromCall(payload) {
    return api.post(ENDPOINTS.removeParticipant(payload.callId), {
      to: payload.to,
    })
  },
  abortAddToCall(payload) {
    return api.post(ENDPOINTS.abortAddCall(payload.callId), {
      abort_to: payload.abort_to,
    })
  },
  mergeAddToCall(payload) {
    return api.post(ENDPOINTS.mergeAddCall(payload.callId))
  },
  startTransfer(payload) {
    return api.post(
      ENDPOINTS.startTransfer(payload.callId),
      payload.groupDetails
    )
  },
  abortTransfer(payload) {
    return api.post(ENDPOINTS.abortTransfer(payload.callId))
  },
  completeTransfer(payload) {
    return api.post(ENDPOINTS.completeTransfer(payload.callId))
  },
  getLiveCalls() {
    return api.get(ENDPOINTS.liveCalls())
  },
  sendCallFeedback(callId, payload) {
    return api.post(ENDPOINTS.callFeedback(callId), payload)
  },
  syncCallToCrm(callId) {
    return api.post(ENDPOINTS.syncCallToCrm(callId))
  },
  bulkExportCalls({ queryParams, body }) {
    let path = ENDPOINTS.bulkExportCalls()
    let pathParams = []

    if (queryParams) {
      const {
        exclusionIds,
        limit,
        orderBy,
        userId,
        dateFrom,
        dateTo,
        dateRange,
        filtersQuery,
      } = queryParams

      if (filtersQuery) pathParams.push(filtersQuery)

      if (dateFrom || dateTo) {
        pathParams.push(
          ENDPOINTS.opts.dialedAt(
            dateTimeRangeInUTC(
              getUserTimeZoneDate(dateFrom),
              getUserTimeZoneDate(dateTo)
            )
          )
        )
      } else if (dateRange) {
        pathParams.push(ENDPOINTS.opts.dialedAt(dateRange))
      }

      if (userId) pathParams.push(ENDPOINTS.opts.userId(userId))

      if (limit) pathParams.push(`limit=${limit}`)

      if (exclusionIds.length)
        pathParams.push(ENDPOINTS.opts.exclusions(exclusionIds))

      if (orderBy) pathParams.push(ENDPOINTS.opts.orderBy(orderBy))
    }

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.post(path, body)
  },
}
