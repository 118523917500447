<template>
  <v-skeleton-loader
    v-if="isLoading"
    :type="loaderType"
    :class="styleClass"
    light
  ></v-skeleton-loader>

  <span
    style="overflow-y: auto"
    :class="applyClassToContent ? styleClass : ''"
    v-else
    ><slot></slot
  ></span>
</template>

<script>
export default {
  name: 'SkeletonLoader',
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    loaderType: {
      type: String,
      required: true,
    },
    styleClass: {
      type: String,
      default: '',
    },
    applyClassToContent: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
}
</script>

<style>
.v-skeleton-loader > .v-skeleton-loader__bone:not(:only-child) {
  border-radius: 0;
}

.v-skeleton-loader > .v-skeleton-loader__bone:first-child:not(:only-child) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.v-skeleton-loader > .v-skeleton-loader__bone:last-child:not(:only-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.v-skeleton-loader > .v-skeleton-loader__heading:only-child {
  border-radius: 12px;
  height: 24px;
  width: 50%;
  margin: 0 auto;
}

.v-skeleton-loader__sentences:not(:last-child) {
  margin-bottom: 10px;
}
</style>
