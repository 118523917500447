var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-chip',{class:_vm.elevation != null
      ? `elevation-${_vm.elevation} ${
          _vm.maxWidth && !_vm.inline ? 'd-flex align-center' : ''
        } ${_vm.inline ? 'd-inline' : ''}`
      : ` ${_vm.maxWidth && !_vm.inline ? 'd-flex align-center' : ''} ${
          _vm.inline ? 'd-inline' : ''
        }`,style:(`${_vm.maxWidth ? `max-width: ${_vm.maxWidth} !important;` : ''} ${
    _vm.inline ? 'padding-block: 5px !important;' : ''
  }`),attrs:{"label":"","disabled":_vm.disabled,"color":_vm.cleanTag.settings.color,"x-small":_vm.size == 'x-small',"small":_vm.size == 'small',"large":_vm.size == 'large',"text-color":_vm.getTextColorFromBG(_vm.cleanTag?.settings?.color)}},[(!_vm.hideIcon && _vm.cleanTag.settings.icon !== 'none')?_c('v-icon',{class:{ 'd-inline': _vm.inline },attrs:{"left":"","x-small":_vm.size == 'x-small',"small":_vm.size == 'small',"large":_vm.size == 'large'},domProps:{"textContent":_vm._s(_vm.cleanTag.settings.icon)}}):_vm._e(),_c('div',{staticClass:"text-truncate",class:{ 'd-inline': _vm.inline },style:(_vm.labelMaxWidth
        ? `max-width: ${_vm.labelMaxWidth}`
        : _vm.maxWidth
        ? `max-width: calc(100% - 16px)`
        : '')},[_vm._v(" "+_vm._s(_vm.cleanTag.name)+" ")]),(_vm.allowDelete)?_c('v-icon',{class:{ 'd-inline': _vm.inline },attrs:{"right":"","x-small":_vm.size == 'x-small',"small":_vm.size == 'small',"dense":_vm.size == 'regular',"large":_vm.size == 'large'},on:{"click":function($event){return _vm.$emit('delete-tag')}}},[_vm._v(" mdi-close ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }