const state = () => ({
  snack: '',
  snackType: '',
  multiLine: false,
  permanent: false,
})

const mutations = {
  setSnack(state, payload) {
    state.snack = payload.snack
    state.snackType = payload.snackType
    state.multiLine = payload.multiLine || false
    state.permanent = payload.permanent
  },
  setPermanent(state, val) {
    state.permanent = val
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
